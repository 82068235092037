import Cookies from 'js-cookie';

// Creare's 'Implied Consent' EU Cookie Law Banner v:2.4
// Conceived by Robert Kent, James Bavington & Tom Foyster
// Put into a namespace and by Bjørn Rosell
//   Also changed behaviour so you have to click accept to make the banner stay away.
//   To make it behave like the original, set "createCookieWhenBannerIsShown" to true.

const CookieBanner = {
  createCookieWhenBannerIsShown: false,
  createCookieWhenAcceptIsClicked: true,
  cookieDuration: 365, // Number of days before the cookie expires, and the banner reappears
  cookieName: "cookieConsent", // Name of our cookie
  cookieValue: "accepted", // Value of cookie

  _createDiv: function (html) {
    var bodytag = document.getElementsByTagName("body")[0];
    var div = document.createElement("div");
    div.setAttribute("id", "cookie-law");
    div.innerHTML = html;

    // bodytag.appendChild(div); // Adds the Cookie Law Banner just before the closing </body> tag
    // or
    bodytag.insertBefore(div, bodytag.firstChild); // Adds the Cookie Law Banner just after the opening <body> tag

    document.getElementsByTagName("body")[0].className += " cookiebanner"; //Adds a class tothe <body> tag when the banner is visible

    if (CookieBanner.createCookieWhenBannerIsShown) {
      CookieBanner.createAcceptCookie();
    }
  },

  _createCookie: function (name, value, days) {
    Cookies.set(name, value, { expires: days });
  },

  _eraseCookie: function (name) {
    CookieBanner._createCookie(name, "", -1);
  },

  createAcceptCookie: function () {
    CookieBanner._createCookie(
      CookieBanner.cookieName,
      CookieBanner.cookieValue,
      CookieBanner.cookieDuration
    ); // Create the cookie
  },

  closeBanner: function() {
    var element = document.getElementById("cookie-law");
    element.className += " not-visible";
    setTimeout(() => {
      element.parentNode.removeChild(element);
    }, 500);
  },

  accept: function () {
    CookieBanner.createAcceptCookie();
    CookieBanner.closeBanner();
  },

  showUnlessAccepted: function(html) {
    if ( Cookies.get(CookieBanner.cookieName) !== CookieBanner.cookieValue ) {
      CookieBanner._createDiv(html);
    }
  }
};

window.CookieBanner = CookieBanner;

export default {
  createAcceptCookie: CookieBanner.createAcceptCookie,
  closeBanner: CookieBanner.closeBanner,
  accept: CookieBanner.accept,
  showUnlessAccepted: CookieBanner.showUnlessAccepted,
};
