import Glider from 'glider-js';
import CookieBanner from './eu_cookie_banner';

const init = () => {
  //Prohibir uso de letras en type="number"
  jQuery("input[type='number'], input[type='tel']").keydown(function(e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if (
      jQuery.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      // Allow: Ctrl/cmd+A
      (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: Ctrl/cmd+C
      (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: Ctrl/cmd+X
      (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  });

  //Input checkbox toggle
  $(
    ".wpcf7-list-item input, .comment-subscription-form input, .comment-form-cookies-consent input, .mc4wp-form-fields p:not(#subscribe-email) label input"
  ).change(function() {
    $("input[type=radio][name=" + this.name + "]")
      .closest("span, p")
      .removeClass("checked");
    $(
      ".wpcf7-exclusive-checkbox input[type=checkbox][name=" + this.name + "]"
    )
      .closest("span, p")
      .removeClass("checked");

    if ($(this).is(":checked")) {
      $(this)
        .closest("span, p")
        .addClass("checked");
    } else {
      $(this)
        .closest("span, p")
        .removeClass("checked");
    }
  });

  //SWIPERS INICIADOS
  if ($(".swiper-container").length) {
    window.swiper = new Glider(document.querySelector(".swiper-wrapper"), {
      slidesToShow: 1,
      //scrollLock: true,
      scrollLockDelay: 50,
      draggable: true,
      dragVelocity: 0.5,
      arrows: {
        prev: ".swiper-button-prev",
        next: ".swiper-button-next",
      },
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  }

  var html =
    '<div class="cookie__wrap"><div class="cookie__text">' +
    "<h5>Nos preocupamos por tu privacidad.</h5>" +
    "<p>Las cookies que utilizamos son exclusivamente para poder analizar las visitas. " +
    'Para más información, revisa nuestra <a href="/informacion-legal/" rel="nofollow noopener" target="_blank">política de privacidad</a>. ' +
    'Al pulsar en "Aceptar", aceptas esta política de cookies.</p>' +
    "</div>";

  // Add the accept button
  html +=
    '<div class="cookie__button"><a href="javascript:void(0);" onclick="CookieBanner.accept();" class="button button--smaller">Aceptar</a></div></div>';

  CookieBanner.showUnlessAccepted(html);

};

export default { init };